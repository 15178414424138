import { AspectRatio, Box, Button, Center, Container, Grid, GridItem, Heading, HStack, Spinner, Stack, useBreakpointValue, useToast } from '@chakra-ui/react'
import { LoginNotice } from 'custom_components/modules/LoginNotice/LoginNotice'
import { Navbar } from 'custom_components/modules/Navbar/Navbar'
import { SmallScreenNotice } from 'custom_components/modules/SmallScreenNotice/SmallScreenNotice'
import { NEXT_PUBLIC_STOCKER_CART_URL, STOCKER_LIST_CONFIGURATIONS_URL } from 'helper/environment'
import { getServerData } from 'helper/serverData'
// import { getServerData } from 'helper/serverData'
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useActiveBasketQuery, useActiveCustomerQuery, useClearBasketMutation, useContactPersonQuery, useCreateSystemConfigMutation, useRemoveBasketLineMutation, useSendBasketToOrderMutation, useSystemsQuery, useUpdateBasketLineMutation } from 'operations/configurator/generated/generated'
import { useIntl } from 'react-intl'

export const getStaticProps: GetStaticProps = async (context) => {
  const [serverData] = await Promise.all([getServerData()])
  return {
    props: {
      revalidateTime: Date.now(),
      ...serverData,
    },
    revalidate: 60,
  }
}

const IndexPage: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  const router = useRouter()
  const toast = useToast()
  const { formatMessage } = useIntl()

  const { data: systems } = useSystemsQuery()

  const { data: activeCustomer, isLoading: activeCustomerIsLoading } = useActiveCustomerQuery()

  const { mutate: createSystemConfigMutation } = useCreateSystemConfigMutation({
    onSuccess: (data) => {
      if (data.createSystemConfig.__typename === 'SystemConfig') {
        return router.push(`/configurator?systemConfigId=${data.createSystemConfig.id}`)
      }
      toast({
        title: 'Fehler',
        description: data.createSystemConfig.message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
      router.push('/')
    },
    onError: (error) => {
      let message = 'Unknown Error'
      if (error instanceof Error) {
        message = error.message
      }
      toast({
        title: 'Fehler',
        description: message,
        status: 'error',
        duration: 10000,
        isClosable: true,
      })
      router.push('/')
    },
  })

  const { data: contactPersonData } = useContactPersonQuery({})
  const contactPerson = contactPersonData?.contactPerson

  const activeBasket = useActiveBasketQuery()
  const updateBasketLine = useUpdateBasketLineMutation({
    onSuccess: () => {
      activeBasket.refetch()
    },
  })
  const removeBasketLine = useRemoveBasketLineMutation({
    onSuccess: () => {
      activeBasket.refetch()
    },
  })
  const sendBasketToOrder = useSendBasketToOrderMutation({
    onSuccess: () => {
      router.push(NEXT_PUBLIC_STOCKER_CART_URL)
    },
  })
  const clearBasket = useClearBasketMutation({
    onSuccess: () => {
      activeBasket.refetch()
    },
  })

  const cartProps = {
    cartItems: activeBasket.data?.activeBasket?.basketLines.map(basketLine => ({
      id: basketLine.id,
      name: basketLine.systemConfig.name,
      quantity: basketLine.quantity,
      price: basketLine.totalCost,
      configurationId: basketLine.systemConfig.id,
    })) ?? [],
    updateBasketLine,
    removeBasketLine,
    sendBasketToOrder,
    basketId: activeBasket.data?.activeBasket?.id ?? '',
    clearBasket,
  }

  const showDeviceTooSmallNotice = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      fallback: 'lg',
    },
  )

  if (showDeviceTooSmallNotice) {
    return (
      <SmallScreenNotice/>
    )
  }

  return (
    <Container maxW="container.page">
      {activeCustomerIsLoading
        ? (
          <Center height="50vh">
            <Spinner size="xl"/>
          </Center>
          )
        : (
          <Box>
            {activeCustomer?.activeCustomer?.id
              ? (
                <Grid
                  templateColumns="repeat(6, 1fr)"
                  columnGap={10}
                  rowGap={5}
                  gridTemplateRows="50px calc(100vh - 120px)"
                >
                  <GridItem colSpan={6}>
                    <Navbar
                      contactPartner={{
                        name: contactPerson?.name ?? '',
                        role: contactPerson?.position ?? '',
                        email: contactPerson?.email ?? '',
                        phone: contactPerson?.phone ?? '',
                        image: {
                          fullpath: contactPerson?.image ?? '',
                        },
                      }}
                      user={{
                        name: `${activeCustomer.activeCustomer.firstName} ${activeCustomer.activeCustomer.lastName}`,
                        shortCode: `${activeCustomer.activeCustomer.firstName.slice(0, 1)}${activeCustomer.activeCustomer.lastName.slice(0, 1)}`,
                        amountOfNotifications: 2,
                        menuItems: [
                          {
                            title: 'Zur Accountverwaltung',
                            href: 'https://stocker-kaminsysteme.com/account',
                          },
                          {
                            title: 'Meine Konfigurationen',
                            href: STOCKER_LIST_CONFIGURATIONS_URL,
                            // hasNotification: true,
                          },
                          {
                            title: 'Neue Konfiguration',
                            href: '/',
                            // hasNotification: true,
                          },
                        ],
                      }}
                      cartProps={cartProps}
                    />
                  </GridItem>
                  <GridItem colSpan={6}>
                    <Box pt={8} pb={3}>
                      <NextImage
                        src="/img/logo_header.svg"
                        width="120px"
                        height="35px"
                      />
                      <Heading lineHeight={1} fontSize="4xl">
                        {formatMessage({ id: '--configurator' })}
                      </Heading>
                    </Box>
                    <HStack pt={12} w="100%" display={{ base: 'none', md: 'flex' }} justify="right">
                      <Box position="relative">
                        <Stack
                          py="35px"
                          position="absolute"
                          right="-80px"
                          top="-270px"
                          w="600px"
                          h="auto"
                          bg="primaryBackground.50"
                          justify="space-between"
                          pr="110px"
                          pl="40px"
                        >
                          <Box>
                            Innerhalb des Partnerportals steht ab sofort der Konfigurator zur Verfügung!
                            <br/><br/>
                            Über eine einfache Abfrage der Eckpunkte und Daten haben Sie die Möglichkeit, den gewünschten Kamin selbst zusammenzustellen. Der Konfigurator schlägt automatisch die korrekten Bauteile vor. Die fertige Konfiguration kann direkt in den Warenkorb übermittelt und im Shop bestellt werden.
                            <br/>
                            Mit dem Konfigurator ist es sehr unkompliziert von der Planung zu einem passend Kaminsystem zu finden. Testen Sie es!
                            <br/>
                            Sollten Sie sich unsicher sein, haben Sie die Möglichkeit, die Konfiguration über einen Expertencheck innerhalb der Stocker Technik prüfen zu lassen.
                            <br/><br/>
                            Der Konfigurator steht vorerst für das System NiroLine DW 25 zur Verfügung. Weitere Systeme folgen.
                            <br/><br/>
                            <b>Disclaimer:</b> Der Konfigurator stellt die Bauteile so zusammen, dass der Kamin entsprechend der von Ihnen eingegebenen Maße zu bauen ist. Hierbei sind persönliche Vorlieben für Bauweisen oder Bauteile natürlich nicht berücksichtigt.
                          </Box>
                          <Button
                            w="100%"
                            colorScheme="accent"
                            iconSpacing={3}
                            onClick={() => createSystemConfigMutation({ systemId: systems?.systems[0].id ?? '' })}
                          >ZUM KONFIGURATOR NIROLINE DW25
                          </Button>
                        </Stack>
                      </Box>
                      <AspectRatio ratio={16 / 9} zIndex={1} minW="calc(100% - 530px)" minH="500px" pos="relative">
                        <NextImage src="/img/Mockup_Konfigurator.jpg" layout="fill" objectFit="cover"/>
                      </AspectRatio>
                    </HStack>
                  </GridItem>
                </Grid>
                )
              : (
                <LoginNotice/>
                )}
          </Box>
          )}
    </Container>
  )
}

export default IndexPage
